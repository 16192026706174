<p>Tu factura será generada con los siguientes datos, por favor asegurate que sean correctos.</p>
<div class="formGroup text-align-center margin-0">
    <label class="centrado-horizontal ch-between">
        <input class="formControl input form-inputs-address width-100" placeholder="Nombre completo"  [(ngModel)]="userData.nombre" >
    </label>
</div>
<div class="formGroup text-align-center margin-0">
    <label class="centrado-horizontal ch-between">
        <input class="formControl input form-inputs-address width-100" placeholder="Correo electronico"  [(ngModel)]="userData.correo" >
        <input class="formControl input form-inputs-address width-100" placeholder="Teléfono"  [(ngModel)]="userData.telefono" >
        <input class="formControl input form-inputs-address width-100" placeholder="RFC"  [(ngModel)]="userData.rfc" >
    </label>
</div>
<!-- <div class="formGroup text-align-center margin-0">
    <label class="centrado-horizontal ch-between">
        <input class="formControl input form-inputs-address width-100" placeholder="Teléfono"  [(ngModel)]="userData.telefono" >
    </label>
</div> -->
<!-- <div class="formGroup text-align-center margin-0">
    <label class="centrado-horizontal ch-between">
        <input class="formControl input form-inputs-address width-100" placeholder="RFC"  [(ngModel)]="userData.rfc" >
    </label>
</div> -->
<div class="formGroup text-align-center margin-0">
    <label class="centrado-horizontal ch-between">
        <input class="formControl input form-inputs-address width-100" placeholder="Descripción"  [(ngModel)]="descripcion" readonly >
    </label>
</div>
<div class="formGroup text-align-center margin-0">
    <label class="centrado-horizontal ch-between">
        <!-- <i class="fas fa-envelope"></i> -->
        <input class="formControl input form-inputs-address width-100" placeholder="Precio" value="$ {{detalles?.costoTotal}}" readonly>
    </label>
</div>
<button class="btn btnSaldo btn-lg cl-white width-100" style="margin-top: 10px;" (click)="facturar()">Facturar envío</button>