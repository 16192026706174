<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0">
        <img class="brand-logo" src="assets/imgs/logo_blanco.png" alt="">
    </a>
</nav>
<div class="container-fluid">
    <div class="row">
        <nav class="col-md-2 col-lg-2 d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link active">
                            <i class="fas fa-home"></i>Inicio
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="modalHistorial()">
                            <i class="fas fa-clipboard-list"></i>Historial
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="modalNotificacionesHistorial()">
                            <i class="fas fa-bell"></i>Notificaciones
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="markers.length > 0 ? 'active' : ''" (click)="oficinas()">
                            <i class="fas fa-map-marker-alt"></i>Oficinas
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="perfil()">
                            <i class="fas fa-user"></i>Perfil
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <main role="main" class="col-md-10 col-lg-10 ml-sm-auto px-4 content-dasbhoard">
            <div class="float-map">
                <div class="formGroup inputs-pin form-dashboard">
                    <i class="fas fa-map-marker-alt pin azul"></i>
                    <input class="formControl input" placeholder="Punto de recolección" #direccionOrigen (click)="opcionDireccion('origen')" [(ngModel)]="dataAddressFrom.direccion" />
                    <i class="fas fa-pen pin" (click)="modalEditarDireccion('origen')" *ngIf="origin.lat !== 0 && origin.lng !== 0"></i>
                </div>
                <div class="formGroup inputs-pin form-dashboard">
                    <i class="fas fa-map-marker-alt pin naranja"></i>
                    <input class="formControl input" placeholder="Punto de entrega" #direccionDestino (click)="opcionDireccion('destino')" [(ngModel)]="dataAddressTo.direccion" />
                    <i class="fas fa-pen pin" (click)="modalEditarDireccion('destino')" *ngIf="destination.lat !== 0 && destination.lng !== 0"></i>
                </div>
                <!-- <div class="formGroup text-align-center" *ngIf="origin.lat !== 0 && origin.lng !== 0 && destination.lat !== 0 && destination.lng !== 0"> -->
                <div class="formGroup text-align-center" >
                    <input type="button" class="btn btnPrimary cl-white width-50" value="Continuar" (click)="buscarRuta()">
                </div>
            </div>
            <agm-map [latitude]="lat" [longitude]="lng" [disableDefaultUI]="true" [zoom]="zoom" class="map-content" (mapClick)="mapClick($event)">
                <agm-marker *ngFor="let mark of markers" [latitude]="mark.lat" [longitude]="mark.lng" [iconUrl]="icon_marca[0]" (markerClick)="markerClick(iw)">
                    <agm-info-window #iw>
                        <h3>{{mark.label}}</h3>
                        <p>Dirección: {{mark.direccion}}</p>
                        <p>Telefono: {{mark.telefono}}</p>
                    </agm-info-window>
                </agm-marker>
                <agm-marker *ngIf="markpersona" [latitude]="mark_persona.lat" [longitude]="mark_persona.lng" [iconUrl]="icon_marca[1]"></agm-marker>
                <agm-direction [origin]="origin" [destination]="destination" *ngIf="origin.lat !== 0 && origin.lng !== 0 && destination.lat !== 0 && destination.lng !== 0"  (onResponse)="eventos($event)" >
                </agm-direction>
            </agm-map>
            <div class="float-map-ubicacion btnPrimary">
                <i class="fas fa-map-marker-alt pin cl-white"></i>
                <input type="button" class="btn cl-white" value="Ver mi ubicación" (click)="miUbicacion()">
            </div>
        </main>
        <!-- <div class="col-2"></div> -->
    </div>
</div>

<!-- Modales -->
<ngx-smart-modal #modalSelect identifier="modalSelect" [dismissable]="false" [escapable]="false">
    <h3>Origen de Dirección</h3>
    <div class="centrado-horizontal" (click)="abrirDireccion()">
        <i class="fas fa-pen"></i>
        <p class="margin-10">Escribir dirección</p>
    </div>
    <div class="centrado-horizontal" (click)="obtenerMiDireccion()">
        <i class="fas fa-map-marker-alt"></i>
        <p class="margin-10">Utilizar mi ubicación</p>
    </div>
</ngx-smart-modal>

<ngx-smart-modal #modalAddress identifier="modalAddress" [dismissable]="false" [escapable]="false">
    <h3>Dirección</h3>
    <div style="margin: 0px 20px 0px 20px;">
        <div class="formGroup text-align-center margin-0">
            <label class="centrado-horizontal ch-between">
                Dirección:
                <input class="formControl input form-inputs-address" placeholder="Dirección" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [(ngModel)]="dataAddress.direccion">
            </label>
        </div>
        <div class="formGroup text-align-center margin-0">
            <label class="centrado-horizontal ch-between">
                Calle:
                <input class="formControl input form-inputs-address" placeholder="Calle"  [(ngModel)]="dataAddress.calle" id="calle">
            </label>
        </div>
        <div class="formGroup text-align-center margin-0">
            <label class="centrado-horizontal ch-between">
                Numero:
                <input class="formControl input form-inputs-address"  placeholder="Numero" [(ngModel)]="dataAddress.numero" id="numero">
            </label>
        </div>
        <div class="formGroup text-align-center margin-0">
            <label class="centrado-horizontal ch-between">
                Ciudad:
                <input class="formControl input form-inputs-address"  placeholder="Ciudad"  [(ngModel)]="dataAddress.ciudad" id="ciudad" >
            </label>
        </div>
        <div class="formGroup text-align-center margin-0">
            <label class="centrado-horizontal ch-between">
                Colonia
                <input class="formControl input form-inputs-address"  placeholder="Colonia"  [(ngModel)]="dataAddress.colonia" id="colonia">
            </label>
        </div>
        <div class="formGroup text-align-center margin-0">
            <label class="centrado-horizontal ch-between">
                Estado:
                <input class="formControl input form-inputs-address"  placeholder="Estado"  [(ngModel)]="dataAddress.estado" id="estado">
            </label>
        </div>
        <div class="formGroup text-align-center margin-0">
            <label class="centrado-horizontal ch-between">
                C.P.:
                <input class="formControl input form-inputs-address"  placeholder="C.P."  [(ngModel)]="dataAddress.cp" id="cp">
            </label>
        </div>
        <div class="formGroup text-align-center">
            <input type="button" class="btn btnPrimary cl-white width-50" value="Guardar" (click)="guardarDireccionmodal()">
        </div>
    </div>
</ngx-smart-modal>

<ngx-smart-modal #modalHistorialPagos identifier="modalHistorialPagos" class="historial" [dismissable]="false" [escapable]="false">
    <h3>Historial de pagos</h3>
    <app-historial [listaHistorialEventos]="listaHistorialEventos"></app-historial>
</ngx-smart-modal>

<ngx-smart-modal #modalNotificaciones identifier="modalNotificaciones" [dismissable]="false" [escapable]="false">
    <h3>Notificaciones</h3>
    <app-notificaciones></app-notificaciones>
</ngx-smart-modal>

<!-- Tipo de paquete -->
<ngx-smart-modal #modalDatosEnvio identifier="modalDatosEnvio" class="historial" [dismissable]="false" [escapable]="false">
    <h3>Datos de envío</h3>
    <div class="container container-maquete">
        <div class="row">
            <div class="col-12" style="margin: 5px 0;">
                <label class="centrado-horizontal ch-between">
                    Nombre quien recibe:
                    <input class="formControl input form-inputs-envio" placeholder=""  [(ngModel)]="envio.nombre_recibe" id="calle">
                </label>
            </div>
            <div class="col-12" style="margin: 5px 0;">
                <label class="centrado-horizontal ch-between">
                    Teléfono quien recibe:
                    <input class="formControl input form-inputs-envio" placeholder=""  [(ngModel)]="envio.telefono_recibe" id="calle">
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="margin: 5px 0;">
                <label class="centrado-horizontal ch-between">
                    ¿Qué contiene tu envío?:
                    <input class="formControl input form-inputs-envio" placeholder=""  [(ngModel)]="envio.que_envia" id="calle">
                </label>
            </div>
            <div class="col-12" style="margin: 5px 0;">
                <label class="centrado-horizontal ch-between">
                    Es necesario agregar una imagen del contenido de tu paquete:
                    <input class="formControl input form-inputs-envio"
                        type="file" (change)="selectFile($event)" />
                  </label>
            </div>
        </div>
    </div>

    <div>
        <h3 style="margin: 0;">Elige un tipo de paquete</h3>
        <div class="container container-maquete">
            <div class="row">
                <div class="col-12" style="margin: 5px 0;">
                    Debes cerrar tu paquete en presencia del mensajero
                </div>
            </div>
            <div class="row">
                <div class="col-6 " *ngFor="let paquete of tipoPaquetes ">
                    <div class="card-paquete" (click)="selectPaquete(paquete)">
                        <div class="row">
                            <div class="col-9">
                                <p class="text-titulo">{{paquete.tipoPaquete}}</p>
                                <p class="text-subtitutlo">
                                    Tamaño: {{paquete.dimensiones}}
                                    <br>
                                    {{paquete.peso}}
                                </p>
                            </div>
                            <div class="col-3 centrado">
                                <div class="icono-caja centrado">
                                    <i class="fas fa-box"></i>
                                </div>
                            </div>
                        </div>
                        <div class="check-paquete" *ngIf="paquete.check">
                            <i class="fas fa-check-circle"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectPaqueteType">
        <h3 style="margin: 0;">Elige un medio de envío</h3>
        <div class="container container-maquete">
            <div class="row">
                <ng-template ngFor let-vehiculo [ngForOf]="tipoVehiculos">
                    <div class="col-6" *ngIf="vehiculo.visible">
                        <div class="card-paquete" (click)="selectVehiculo(vehiculo)" >
                            <div class="row">
                                <div class="col-9">
                                    <p class="text-titulo">{{vehiculo.tipoVehiculo}}</p>
                                    <p class="text-subtitutlo">
                                        Costo inicial base: {{vehiculo.costoArranque | currency}}
                                        <br>
                                        Kilómetros incluidos: {{vehiculo.kilometrosArranque}} km.
                                        <br>
                                        Costo por kilómetros adicional: {{vehiculo.costoKilometro | currency}}
                                    </p>
                                </div>
                                <div class="col-3 centrado">
                                    <div class="icono-caja centrado">
                                        <i class="fas fa-motorcycle" *ngIf="vehiculo.icon === 'motorcycle'"></i>
                                        <i class="fas fa-car" *ngIf="vehiculo.icon === 'directions_car'"></i>
                                        <i class="fas fa-shuttle-van" *ngIf="vehiculo.icon === 'airport_shuttle'"></i>
                                        <i class="fas fa-truck" *ngIf="vehiculo.icon === 'local_shipping'"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-titulo">Contenido</p>
                                    <p class="text-subtitutlo">
                                        {{vehiculo.descripcion}}
                                    </p>
                                </div>
                            </div>
                            <div class="check-paquete" *ngIf="vehiculo.check">
                                <i class="fas fa-check-circle"></i>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="material_prohibido">
        <div class="container">
            <div class="row" (click)="materiales_prohibidos()">
                <div class="col-12">
                    Materiales prohibidos
                    <i class="fas fa-info-circle azul"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="formGroup text-align-center" style="margin: 10px 0;" *ngIf="selectPaqueteType && selectVehiculoType">
        <input type="button" class="btn btnSaldo cl-white width-50" value="Siguiente" (click)="siguienteEnvio()">
    </div>
</ngx-smart-modal>

<ngx-smart-modal #modalResumenEnvio identifier="modalResumenEnvio" class="saldo" [dismissable]="false" [escapable]="false">
    <h3>Tipo de envío</h3>
    <div class="card-paquete">
        <div class="row">
            <div class="col-12">
                <p class="text-titulo-big">Resumen del pedido</p>
                <p class="text-titulo">{{detallesEnvio?.paquete.tipoPaquete}}</p>
                <p class="text-subtitutlo">
                    Tamaño: {{detallesEnvio?.paquete.dimensiones}}
                    <br>
                    Peso: {{detallesEnvio?.paquete.peso}}
                </p>
            </div>
            <div class="col-12">
                <p class="text-subtitutlo">
                    Distancia:
                    <br>
                    {{detallesEnvio?.distancia}} Kilómetros.
                </p>
            </div>
            <div class="col-12">
                <p class="text-subtitutlo">
                    Costo de envío:
                    <br>
                    {{detallesEnvio?.total | currency}}
                </p>
            </div>
        </div>
    </div>
    <div class="material_prohibido">
        <div class="container">
            <div class="row" (click)="materiales_prohibidos()">
                <div class="col-12">
                    Agregar Seguro de envío
                    <i class="fas fa-info-circle azul"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="material_prohibido">
        <div class="container">
            <div class="row" (click)="materiales_prohibidos()">
                <div class="col-12">
                    Politicas de recolección
                    <i class="fas fa-info-circle azul"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="formGroup text-align-center" style="margin: 10px 0;" *ngIf="selectPaqueteType && selectVehiculoType">
        <input type="button" class="btn btnSaldo cl-white width-50" value="Siguiente" (click)="siguienteResumen()">
    </div>
    
</ngx-smart-modal>