<div class="displayTable">
    <div class="displayTableCell">
        <div class="px-logo logo-login">
            <a href="#" target="_blank">
                <img src="assets/imgs/fondo_login.png" alt="">
            </a>
        </div>
        <div class="authBlock padding-fonm-login">
            <h3>Registro</h3>
            <div class="formGroup">
                <input type="email" class="formControl" placeholder="Nombre y apellido" #userNombre [(ngModel)]="data.userNombre" required>
            </div>
            <div class="formGroup">
                <input type="email" class="formControl" placeholder="Número de celular" #userCelular [(ngModel)]="data.userCelular" required>
            </div>
            <div class="formGroup">
                <input type="email" class="formControl" placeholder="Correo electronico" #userEmail [(ngModel)]="data.userEmail" required>
            </div>
            <div class="formGroup">
                <input type="email" class="formControl" placeholder="RFC (Opcional para facturación)" #userRFC [(ngModel)]="data.userRFC">
            </div>
            <div class="formGroup">
                <input type="password" class="formControl" placeholder="Contraseña" #userPwd required [(ngModel)]="data.userPwd">
            </div>
            <div class="formGroup">
                <input type="password" class="formControl" placeholder="Confirmar contraseña" #userPwd2 required [(ngModel)]="data.userPwd2">
            </div>
            <div class="formGroup">
                <div class="row">
                    <div class="col-2">
                        <i class="fas fa-info-circle azul" (click)="vertyc()"></i>
                    </div>
                    <div class="col-8">
                        <label class="form-check-label">
                            Acepto los terminos y condiciones
                          </label>
                    </div>
                    <div class="col-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="tyc">
                        </div>
                    </div>
                </div>

            </div>

            <div class="formGroup">
                <input type="button" class="btn btnPrimary" value="Registrarme" (click)="registrar()">
            </div>
        </div>
        <div class="forgotPassword">
            <span class="padding-t10">¿Ya tienes una cuenta? <span class="redirect padding-0" routerLink="/login">Inicia Sesión</span></span>
        </div>
    </div>
</div>