import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule} from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AuthService } from './shared/services/authservice';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PerfilComponent } from './components/perfil/perfil.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { HistorialComponent } from './components/historial/historial.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones.component';
import { SaldoComponent } from './components/historial/saldo/saldo.component';
import { DetallesComponent } from './components/historial/detalles/detalles.component';
import { NgxPaymentCardModule } from 'ngx-payment-card'; 
import { NgxStripeModule } from 'ngx-stripe';
import { CardComponent } from './components/historial/card/card.component';
import { RecargaComponent } from './components/historial/recarga/recarga.component';
import { FacturarComponent } from './components/historial/facturar/facturar.component';
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    PerfilComponent,
    HistorialComponent,
    NotificacionesComponent,
    SaldoComponent,
    DetallesComponent,
    CardComponent,
    RecargaComponent,
    FacturarComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot(environment.googlemaps),
    AgmDirectionModule,
    GooglePlaceModule,
    NgxSmartModalModule.forRoot(),
    HttpClientModule,
    NgxPaymentCardModule,
    NgxStripeModule.forRoot(environment.keystripe.pk),
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
