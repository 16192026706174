<div class="container "> 
    <div class="row facturacion" (click)="facturar()">
        <div class="col-11">
            Facturación (No facturado)
        </div>
        <div class="col-1">
            <i class="fas fa-check-circle texto-gray"></i>
        </div>
    </div>
</div>
<div class="container margin10-0">
    <div class="row">
        <div class="col-6">
            Estatus
        </div>
        <div class="col-6 text-derecha texto-gray">
            {{ detalles?.estatus }}
        </div>
    </div>
    <div class="row">
        <div class="col-6" >
            Fecha de creación
        </div>
        <div class="col-6 text-derecha texto-gray">
            {{ detalles?.referencia | date:'dd LLL yyyy' }}
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            Hora de creación
        </div>
        <div class="col-6 text-derecha texto-gray">
            {{ detalles?.referencia | date:'hh:mm aa' }}
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            Referencia
        </div>
        <div class="col-6 text-derecha texto-gray">
            {{ detalles?.referenciaStripe }}
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-6">
            <div class="container informacion-pedido">
                <div class="row">
                    <div class="col-12 titulo-informacion">
                        Información de pedido
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Datos generales
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class= "marginl10 texto15">
                            Tamaño: {{detalles?.paqueteDimensiones}}<br>
                            Peso: {{detalles?.paquetePeso}}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Distancia
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class= "marginl10 texto15">{{detalles?.distanciaKM}} Kilómetros.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Vehículo seleccionado
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class= "marginl10 texto15">{{detalles?.vehiculo}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Costo de envío
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class= "marginl10 texto15">Costo por envío: {{detalles?.costoEnvio | currency}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        Total pagado
                    </div>
                    <div class="col-6 text-derecha">
                        {{detalles?.costoTotal | currency}}
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-6">
            <div class="container margin10-0">
                <div class="row">
                    <div class="col-2">
                        <i class="fas fa-map-marker-alt marker color1"></i>
                    </div>
                    <div class="col-10">
                        <strong>Datos de recolleción</strong>
                        <p class="texto13-gray">
                            {{detalles?.recoleccionNombreUsuario}}<br>
                            {{detalles?.recoleccionDireccion}}<br>
                            {{detalles?.recoleccionCP}}<br>
                            {{detalles?.recoleccionTelefono}}<br>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2" >
                        <i class="fas fa-map-marker-alt marker color2"></i>
                    </div>
                    <div class="col-10">
                        <strong>Datos de entrega</strong>
                        <p class="texto13-gray">
                            {{detalles?.entregaNombreUsuario}}<br>
                            {{detalles?.entregaDireccion}}<br>
                            {{detalles?.entregaCP}}<br>
                            {{detalles?.entregaTelefono}}<br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-smart-modal #modalFacturar identifier="modalFacturar" class="recarga-tarjeta" [dismissable]="false" [escapable]="false">
    <h3>Facturar envío</h3>
    <app-facturar [detalles]="detalles"></app-facturar>
</ngx-smart-modal>
