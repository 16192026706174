<div class="displayTable">
    <div class="displayTableCell">
        <div class="px-logo logo-login">
            <a href="#" target="_blank">
                <img src="assets/imgs/fondo_login.png" alt="">
            </a>
        </div>
        <div class="authBlock padding-fonm-login">
            <h3>Iniciar Sesión</h3>
            <div class="formGroup">
                <input type="text" class="formControl" placeholder="Numero de teléfono" #userName [(ngModel)]="data.telefono" required>
            </div>

            <div class="formGroup">
                <input type="password" class="formControl" placeholder="Contraseña" #userPassword [(ngModel)]="data.password" required>
            </div>
            <div class="formGroup">
                <input type="button" class="btn btnPrimary" value="Iniciar Sesión" (click)="iniciarSesion()">
            </div>

            <div class="forgotPassword">
                <span routerLink="/recuperar-password" class="padding-t10">¿Olvidaste tu Contraseña?</span>
                <span class="padding-0">¿Aún no tienes una cuenta? <span class="redirect padding-0" routerLink="/registrar"> Regístrate</span></span>
            </div>
        </div>
    </div>
</div>

<ngx-smart-modal #modalAddressLoading identifier="modalAddressLoading" [dismissable]="false" [escapable]="false">
    <div class="centrado-horizontal">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"> </div>
        <h3 style="margin: 20px !important;">Verificando informacion...</h3>
    </div>
</ngx-smart-modal>