<div class="row">
    <div class="col-5">
        <div class="card-tarjeta-existente">
            <div>
                Numero de tarjeta
            </div>
            <div>
                **** **** **** {{ infoCard?.last4 }}
            </div>
            <br>
            <div>
                Fecha de expiración
            </div>
            <div>
                {{infoCard?.exp_month}} / {{infoCard?.exp_year}}
            </div>
        </div>
    </div>
    <div class="col-7">
        <div style="margin-bottom: 10px;">
            Sus datos estás protegidos, su pago será procesado de forma seguro con Stripe.
        </div>
        <div>
            <input class="formControl" placeholder="Monto a recargar" [(ngModel)]="amount" type="number" min="100" style="margin-bottom: 15px;" />
            <div>
                Escriba el CVV de tu tarjeta para confirmar la compra
            </div>
        </div>
        <button class="btn btnDelete btn-lg cl-white width-100" style="margin-top: 10px;" (click)="eliminarTarjeta()">ELIMINAR TARJETA</button>
        <button class="btn btnSaldo btn-lg cl-white width-100" style="margin-top: 10px;" (click)="crearCargo()">RECARGAR SALDO</button>
    </div>
</div>

<!-- INfo de card: INfo de card: {{ infoCard | json }} -->