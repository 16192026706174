import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/authservice';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  @Input() listaHistorialEventos:any;
  @ViewChild("modalDetalleEnvioHistorial") modalDetalleEnvioHistorial;
  @ViewChild("modalDetalleSaldo") modalDetalleSaldo;
  detallesEnvio:any;
  cards;
  constructor(
    public as: AuthService,
  ) { 
    this.cards=[];
  }

  ngOnInit(): void {
    
  }

  detalleEnvio(evento){
    console.log(evento);
    this.detallesEnvio = evento;
    this.modalDetalleEnvioHistorial.open();
  }

  
  saldo(){
    let userData = JSON.parse(localStorage.getItem('userData'));
    this.as.getAllCardsCustomer(userData.stripeID).then( (cards:any) => {
      this.cards = cards.data;
      this.modalDetalleSaldo.open();
    });
  }
  

  reloadCardsEvent(event){
    console.log(event);
  }

}
