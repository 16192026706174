<div>
    <div>
        <h3>Perfil</h3>
        <table class="width-100 ">
            <tr>
                <th style="width: 60%">
                    <div>
                        <div class="formGroup text-align-center margin-0">
                            <label class="centrado-horizontal ch-between">
                                <i class="fas fa-envelope"></i>
                                <input class="formControl input form-inputs-address" placeholder="Correo electrónico"  [(ngModel)]="userData.correo" readonly disabled>
                            </label>
                        </div>
                        <div class="formGroup text-align-center margin-0">
                            <label class="centrado-horizontal ch-between">
                                <i class="fas fa-phone"></i>
                                <input class="formControl input form-inputs-address" placeholder="Teléfono celular"  [(ngModel)]="userData.telefono" disabled>
                            </label>
                        </div>
                        <div class="formGroup text-align-center margin-0">
                            <label class="centrado-horizontal ch-between">
                                <i class="fas fa-bookmark"></i>
                                <input class="formControl input form-inputs-address" placeholder="RFC (Para facturación)"  [(ngModel)]="userData.rfc">
                            </label>
                        </div>
                        <div class="formGroup text-align-center margin-0">
                            <label class="centrado-horizontal ch-between">
                                <i class="fas fa-key"></i>
                                <input class="formControl input form-inputs-address" placeholder=" Contraseña actual"  [(ngModel)]="passwordOriginal" type="password">
                            </label>
                            <label class="centrado-horizontal ch-between">
                                <i class="fas fa-key"></i>
                                <input class="formControl input form-inputs-address" placeholder="Nueva contraseña"  [(ngModel)]="passwordNuevo" type="password">
                            </label>
                        </div>
                        <div class="formGroup text-align-center">
                            <input type="button" class="btn btnPrimary cl-white width-50" value="Guardar" (click)="guardar()">
                        </div>
                    </div>
                </th>
                <th style="width: 40%">
                    <div class="text-center">
                        <i class="fas fa-power-off close-sesion" (click)="signOut()"></i>
                        <div style="margin: 10px">


                            <label class="custom-file-upload">
                                <div class="centrado-horizontal perfil-foto-none" (click)="cambiarFoto()" *ngIf="userData.avatar === ''">
                                    <i class="fas fa-camera centrado-horizontal "></i>
                                </div>    
                                <img [src]="userData.avatar" class="imagen-perfil" *ngIf="userData.avatar !== ''" (click)="cambiarFoto()" />
                                <input type="file" id="file" #userPhoto (change)="onFileSelected($event)" name="image" autocomplete="off" />
                            </label>
                        </div>
                        <h3>
                            {{userData.nombre}}
                        </h3>
                    </div>
                </th>
            </tr>
        </table>


    </div>

</div>