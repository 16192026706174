<div class="card card-fondo" (click)="saldo()">
    <div class="card-body">
        <h5 class="card-title">Balance de saldo</h5>
        <div class="">
            <div class="row">
                <div class="col-11">
                    <p class="card-text">$ 0.00</p>
                </div>
                <div class="col-1">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div>
    <div class="items-historial" *ngFor="let evento of listaHistorialEventos; let i = index">
        <div class="row" (click)="detalleEnvio(evento)">
            <div class="col-2">
                <div class="item-sobre centrado-horizontal">
                    <i class="fas fa-envelope margin-0"></i>
                </div>
            </div>
            <div class="col-7">
                <p class="texto-item-historial">{{evento.paqueteTipo}}</p>
                <p class="sub-texto-item-historial">Costo de envío<br>{{evento.estatus}}</p>
            </div>
            <div class="col-3">
                <p class="sub-texto-item-historial">{{evento.referencia | date:'dd LLL yyyy' }}</p>
                <p class="texto-item-historial">{{evento.costoEnvio | currency}}</p>
            </div>
        </div>
    </div>
</div>

<ngx-smart-modal #modalDetalleSaldo identifier="modalDetalleSaldo" class="saldo" [dismissable]="false" [escapable]="false">
    <h3>Recargar saldo</h3>
    <app-saldo [dataCards]="cards"></app-saldo>
</ngx-smart-modal>

<ngx-smart-modal #modalDetalleEnvioHistorial identifier="modalDetalleEnvioHistorial" class="saldo" [dismissable]="false" [escapable]="false">
    <h3>Historial de envíos</h3>
    <app-detalles [detalles]="detallesEnvio"></app-detalles>
</ngx-smart-modal>
