// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDWnIqRP90CXsyrlpaXuAfjF_rlZ69sdlw",
    authDomain: "dag-packet.firebaseapp.com",
    databaseURL: "https://dag-packet.firebaseio.com",
    projectId: "dag-packet",
    storageBucket: "dag-packet.appspot.com",
    messagingSenderId: "493302786072",
    appId: "1:493302786072:web:c4e74c6f1f156406203b6d",
    measurementId: "G-XE3WJS2VQC"
  },
  googlemaps: {
    apiKey: 'AIzaSyCMXk_X1e8i03wRXqyVcFMS_wTh9jimddA',
    libraries: [ 'places']
  },
  keystripe: {
    sk:'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',
    pk: 'pk_live_51Gwyb3CtwxNQsCFOgPTz5W88p6iFHkdEqBlT2luyOuVp1gjK2uCfLjzBebOqOsWyD64tkO3Q6ECFRIBK4xdjlNEc001Evrvagu'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
