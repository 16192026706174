import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from 'src/app/shared/services/authservice';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  @ViewChild("modalAddressLoading") modalAddressLoading;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public authService: AuthService
  ) { }

  data = {
    telefono: '',
    password: ''
  };

  ngOnInit(): void {
  }

  iniciarSesion(){
    this.authService.validLogin(this.data);
    this.modalAddressLoading.open();
  }

}
