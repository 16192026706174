import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/authservice';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private toastr: ToastrService 
  ) { }

  data = {
    userNombre : '',
    userCelular: '',
    userEmail: '',
    userRFC: '',
    userPwd: '',
    userPwd2: ''
  };
  tyc = false;

  ngOnInit(): void {
  }

  vertyc(){

  }

  registrar(){
    if(this.data.userPwd === this.data.userPwd2 && this.data.userPwd !== ''){
      if(this.tyc){
        let errores = this.verificar_form();
        if(errores.length === 0){
          this.authService.SignUp(this.data);
        } else {
          this.toastr.warning(errores);
        }
      } else{
        this.toastr.warning('Para registrar es necesario aceptar los Terminos y Condiciones');
      }
    } else {
      if(this.data.userPwd === ''){
        this.toastr.warning('La contraseña no puede estar vacia');
      } else {
        this.toastr.warning('La contraseña no coincide');
      }
    }
  }

  verificar_form(){
    let mensaje = '';
    let mensajeRetorno = '';
    let errores = 0;
    if(this.data.userNombre === ''){
      errores += 1;
      mensaje += 'Nombre, ';
    }
    if(this.data.userCelular === ''){
      errores += 1;
      mensaje += 'Celular, ';
    }
    if(this.data.userEmail === ''){
      errores += 1;
      mensaje += 'Correo, ';
    }
    if(this.data.userPwd === ''){
      errores += 1;
      mensaje += 'Contraseña, ';
    }
    if(errores === 1){
      mensajeRetorno = 'Es necesario el campo de: ' + mensaje.substring(0, mensaje.length - 2) ;
    } else if(errores > 1){
      mensajeRetorno += 'No se pueden dejar los campos vacios de: [' + mensaje.substring(0, mensaje.length - 2) + ']';
    } else {
      mensajeRetorno = '';
    }
    return mensajeRetorno;
  }
}
