import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dagpacket';
  constructor( 
    public router: Router
  ) { }
  ngOnInit(): void {
    let user = localStorage.getItem('user');
    if(user !== null){
      this.router.navigate(['dashboard']);
    }
  }
}
