import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { AuthService } from 'src/app/shared/services/authservice';

@Component({
  selector: 'app-saldo',
  templateUrl: './saldo.component.html',
  styleUrls: ['./saldo.component.scss']
})
export class SaldoComponent implements OnInit {
  @Input() dataCards:any;
  @ViewChild("modalNuevaTarjeta") modalNuevaTarjeta;
  @ViewChild("modalRecargaTarjeta") modalRecargaTarjeta;
  constructor(
    private authservice: AuthService
  ) {}
 
  ngOnInit(): void { }
 
  getCards(event){
    if(event){
      let userData = JSON.parse(localStorage.getItem('userData'));
      this.authservice.getAllCardsCustomer(userData.stripeID).then( (cards:any) => {
        this.dataCards = cards.data;
        console.log(this.dataCards);
      });
    }
  }

  agregarTarejta(){
    this.modalNuevaTarjeta.open();
  }

  infoCard;
  recargaTarjeta(card){
    this.infoCard = card;
    this.modalRecargaTarjeta.open();
  }
  
}
