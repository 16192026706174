import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent} from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/authservice';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @Output() reloadCardsEvent  = new EventEmitter<boolean>();
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
 
  elementsOptions: StripeElementsOptions = {
    locale: 'es'
  };
 
  stripeTest: FormGroup;
 
  constructor(
    private fb: FormBuilder, 
    private toastr: ToastrService,
    private authService: AuthService,
    private ngxSmartModalService: NgxSmartModalService,
    private stripeService: StripeService) {}
 
  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }
 
  createToken(): void {
    let userData = JSON.parse(localStorage.getItem('userData'));
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          console.log(result.token.id);
          this.authService.setCardCustomerStripe(result.token.id, userData.stripeID).then( (resp:any) => {
              this.reloadCardsEvent.emit(true);
              let modal = this.ngxSmartModalService.getModal("modalNuevaTarjeta");
              modal.close();
          });
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
          this.toastr.error(result.error.message);
        }
      });
  }
  
}
