import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/authservice';

import { AngularFireStorage } from "@angular/fire/storage";
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  userData:any;
  userKey:any;
  passwordNuevo:any;
  passwordOriginal:any;

  downloadURL: Observable<string>;
  fb;

  constructor(
    public authService: AuthService,
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService, // Toast
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.userKey =  localStorage.getItem('userKey');
  }

  cambiarFoto(){
    this.authService.refreshDataLogin(this.userKey).then( resp => {
      if(resp){
        this.userData = JSON.parse(localStorage.getItem('userData'));
      }
    });
  }

  guardar(){
    this.authService.saveDataUser(this.userData, this.userKey);
    if(this.passwordOriginal !== undefined && this.passwordNuevo !== undefined) {
      this.authService.changePassword(this.userData.correo, this.passwordOriginal, this.passwordNuevo);
      let modal = this.ngxSmartModalService.getModal("popModal");
      modal.close();
    } else {
      this.toastr.success('La información se guardo correctamente');
    }
  }

  signOut(){
    this.authService.SignOut();
    let modal = this.ngxSmartModalService.getModal("popModal");
    modal.close();
  }

  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = 'usuarios/'+this.userKey+"/avatar";
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload('usuarios/'+this.userKey+"/avatar", file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
              this.authService.saveDataUserAvatar(this.fb, this.userKey).then( resp => {
                if(resp){
                  this.userData = JSON.parse(localStorage.getItem('userData'));
                }
              });
            }
            console.log(this.fb);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          console.log(url);
          //this.authService.saveDataUserAvatar(url, this.userKey);
        }
      });
  }
}
