import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import firebase from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public afdb: AngularFireDatabase,
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning,
    private toastr: ToastrService, // Toast
    private http: HttpClient
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  // Sign in with email/password
  SignIn(email, password, dataBD, key) {
    let modal = this.ngxSmartModalService.getModal('modalAddressLoading');
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(dataBD, key);
        this.ngZone.run(() => {
          setTimeout(() => {
            modal.close();
            this.router.navigate(['dashboard']);
          }, 3000);
        });
      }).catch((error) => {
        this.toastr.error(error.message);
        modal.close();
    });
  }

  // Sign up with email/password
  SignUp(data) {
    return this.afAuth.createUserWithEmailAndPassword(data.userEmail, data.userPwd)
      .then((result) => {
        this.saveNewUser(data);
      }).catch((error) => {
        this.toastr.error(error.message);
    });
  }

  saveNewUser(data){
    return this.afAuth.currentUser.then( user => {
      this.setCustomerStripe(data).then( stripeID => {
        let dataUser = {
          avatar: '',
          balance: 0,
          borrador: false,
          correo: data.userEmail,
          fechaRegistro: new Date().getTime(),
          nombre: data.userNombre,        
          rfc: data.userRFC,
          stripeID: stripeID,
          telefono: data.userCelular,
          totalCompras: 0,
          ultimaConexion: new Date().getTime(),
        };
        this.afdb.object('/usuarios/'+user.uid).set(dataUser).then( () => {
          let dataUserTelefono = {
            correo: data.userEmail
          };
          this.afdb.object('/usuariosTelefono/'+data.userCelular).set(dataUserTelefono).then( () => {
            this.SendVerificationMail();
          });
        });
      })
    }); 
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser.then( user => {
        // Agregar el registro a la BD
        user.sendEmailVerification().then( ()=> {
            this.router.navigate(['verificar-email']);
        })
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      this.toastr.success('Correo electrónico de restablecimiento de contraseña enviado, revise su bandeja de entrada.');
    }).catch((error) => {
      this.toastr.error(error.message);
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  SetUserData(user, key) {
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('userKey', key);
  }

  // Sign out 
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('userData');
      localStorage.removeItem('userKey');
      this.router.navigate(['login']);
    })
  }

  RegisterBD(data){
    let dataBD = {
      avatar: '',
      balance: 0,
      borrado: false,
      correo: data.userEmail,
      fechaRegistro: new Date().getTime(),
      nombre: data.userNombre,
      telefono: data.userCelular,
      totalCompras: 0,
      ultimaConexion: new Date().getTime(),
      rfc: data.userRFC
    };
    this.afdb.object('usuarios').set(dataBD);
  }

  validLogin(data){
    this.afdb.list('/usuarios', ref => ref.orderByChild('telefono').equalTo(data.telefono)).snapshotChanges().subscribe((action:any) => {
      let key = action[0].key;
      let dataBD = action[0].payload.val();
      this.SignIn(dataBD.correo, data.password, dataBD, key);
    });
  }

  saveDataUser(data, key){
    let dataUpdate = {
      rfc: data.rfc
    }
    this.afdb.object('/usuarios/'+key).update(dataUpdate).then( () => {
      this.afdb.object('/usuarios/'+key).snapshotChanges().subscribe((action:any) => {
        let key = action.key;
        let dataBD = action.payload.val();
        this.SetUserData(dataBD, key);
      });
    });
  }

  saveDataUserAvatar(avatar, key): Promise<any>{
    return new Promise((resolve, reject) => {
      let dataUpdate = {
        avatar: avatar
      }
      this.afdb.object('/usuarios/'+key).update(dataUpdate).then( () => {
        this.afdb.object('/usuarios/'+key).snapshotChanges().subscribe((action:any) => {
          let key = action.key;
          let dataBD = action.payload.val();
          this.SetUserData(dataBD, key);
          resolve(true);
        });
      });
    });
  }

  changePassword(correo, passwordOld, passwordNew){
    return this.afAuth.signOut().then(() => {
      return this.afAuth.signInWithEmailAndPassword(correo, passwordOld)
      .then((result) => {
        this.afAuth.currentUser.then(user => {
          user.updatePassword(passwordNew).then( ()=> {
              this.SignOut();
              this.toastr.success('Inicie sesión nuevamente');
          })
        });
      });
    });
  }

  refreshDataLogin(key): Promise<any>{
    return new Promise((resolve, reject) => {
        this.afdb.object('/usuarios/'+ key).snapshotChanges().subscribe((action:any) => {
        this.SetUserData(action.payload.val(), key);
        resolve(true);
      }); 
    });
  }

  getListEvents(key): AngularFireList<any> {
    return  this.afdb.list('/historialUsuario/'+key);
  }

  setCustomerStripe(dataUser){
    return new Promise((resolve, reject) => {
      this.afdb.object('/clavesAPI/sk/').snapshotChanges().subscribe((action:any) => {
        let token = action.payload.val();
        let formData = new URLSearchParams();
        formData.set('email', dataUser.userEmail);
        formData.set('name', dataUser.userNombre);
        formData.set('phone', dataUser.userCelular);
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
          })
        };
        this.http.post('https://api.stripe.com/v1/customers',formData.toString(), httpOptions).subscribe( (resp:any) => {
          console.log(resp);
          resolve(resp.id);
        });
      });
    });
  }

  setCardCustomerStripe(dataCard, customer){
    return new Promise((resolve, reject) => {
      this.afdb.object('/clavesAPI/sk/').snapshotChanges().subscribe((action:any) => {
        let token = action.payload.val();
        let formData = new URLSearchParams();
        formData.set('source', dataCard);
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
          })
        };
        this.http.post('https://api.stripe.com/v1/customers/' + customer + '/sources',formData.toString(), httpOptions).subscribe( (resp:any) => {
          console.log(resp);
          resolve(resp.id);
        });
      });
    });
  }

  getAllCardsCustomer(customer){
    return new Promise((resolve, reject) => {
      this.afdb.object('/clavesAPI/sk/').snapshotChanges().subscribe((action:any) => {
        let token = action.payload.val();
        let formData = new URLSearchParams();
        formData.set('source', customer);
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
          })
        };
        this.http.get('https://api.stripe.com/v1/customers/' + customer + '/sources', httpOptions).subscribe( (resp:any) => {
          resolve(resp);
        });
      });
    });
  }

  chargeCustomer(amount, customer, id_card){
    return new Promise((resolve, reject) => {
      this.afdb.object('/clavesAPI/sk/').snapshotChanges().subscribe((action:any) => {
        let token = action.payload.val();
        let formData = new URLSearchParams();
        formData.set('amount', amount);
        formData.set('currency', 'mxn');
        formData.set('customer', customer);
        formData.set('source', id_card);
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
          })
        };
        this.http.post('https://api.stripe.com/v1/charges', formData, httpOptions).subscribe( (resp:any) => {
          //console.log(resp);
          resolve(resp);
        });
      });
    });
  }

  deleteCardCustomer(customer, id_card){
    return new Promise((resolve, reject) => {
      this.afdb.object('/clavesAPI/sk/').snapshotChanges().subscribe((action:any) => {
        let token = action.payload.val();
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
          })
        };
        this.http.delete('https://api.stripe.com/v1/customers/' + customer + '/sources/' + id_card, httpOptions).subscribe( (resp:any) => {
          //console.log(resp);
          resolve(resp);
        });
      });
    });
  }

  paquetesGet(): Promise<any>{
    return new Promise((resolve, reject) => {
        this.afdb.object('/paquetes').snapshotChanges().subscribe((paquetes:any) => {
          let paquetesJSON = paquetes.payload.val();
          let keyPaquete = Object.keys(paquetesJSON);
          let arrayPaquetes = [];
          keyPaquete.forEach(keyPaquete => {
            let elementoPaquete =  paquetesJSON[keyPaquete];
            elementoPaquete = {...elementoPaquete, key: keyPaquete, check: false};
            arrayPaquetes.push(elementoPaquete);
          });
        resolve(arrayPaquetes);
      }); 
    });
  }

  vehiculosGet(): Promise<any>{
    return new Promise((resolve, reject) => {
        this.afdb.object('/vehiculos').snapshotChanges().subscribe((vehiculos:any) => {
          let vehiculosJSON = vehiculos.payload.val();
          let keyVehiculos = Object.keys(vehiculosJSON);
          let arrayVehiculos = [];
          keyVehiculos.forEach(keyVehiculo => {
            let elementoPaquete =  vehiculosJSON[keyVehiculo];
            elementoPaquete = {...elementoPaquete, key: keyVehiculo, check: false, visible: false};
            arrayVehiculos.push(elementoPaquete);
          });
        resolve(arrayVehiculos);
      }); 
    });
  }

}