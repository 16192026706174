<div class="displayTable">
    <div class="displayTableCell">
        <div class="px-logo logo-login">
            <a href="#" target="_blank">
                <img src="assets/imgs/fondo_login.png" alt="">
            </a>
        </div>
        <div class="authBlock  padding-fonm-login">
            <h3>Restablecer Contraseña</h3>
            <p class="text-center">Ingrese su dirección de correo electrónico para solicitar un restablecimiento de contraseña.</p>
            <div class="formGroup">
                <input type="email" class="formControl" placeholder="Email" #passwordResetEmail required>
            </div>
            <div class="formGroup">
                <input type="submit" class="btn btnPrimary" value="Reset Password" (click)="authService.ForgotPassword(passwordResetEmail.value)">
            </div>
            <div class="forgotPassword">
                <span class="padding-t10">Ir a <span class="redirect padding-0" routerLink="/login">Iniciar Sesión</span></span>
            </div>
        </div>
    </div>
</div>