import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-facturar',
  templateUrl: './facturar.component.html',
  styleUrls: ['./facturar.component.scss']
})
export class FacturarComponent implements OnInit {
  @Input() detalles:any;
  userData;
  descripcion = '';
  precio= '$0.00';

  constructor(
    private ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService
  ) { 
    this.descripcion= 'Servicio de paquetería y mensajeria local';
    this.precio= '$0.00';
  }

  ngOnInit(): void {
    this.descripcion= 'Servicio de paquetería y mensajeria local';
    this.precio= '$0.00';
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  facturar(){
    let modal = this.ngxSmartModalService.getModal("modalFacturar");
    let modal2 = this.ngxSmartModalService.getModal("modalDetalleEnvioHistorial");
    this.toastr.success('Su factura sera enviada al correo ingresado.');
    modal.close();
    modal2.close();
  }

}
