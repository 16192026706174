import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from 'src/app/shared/services/authservice';
import { PerfilComponent } from '../perfil/perfil.component';
import { finalize, map } from 'rxjs/operators';
import { AgmInfoWindow } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public zoom: any;
  public lat: any;
  public lng: any;
  public origin: any;
  public destination: any;
  public origenEnvio: any;
  public destinoEnvio: any;
  public entradaDatosDireccion = '';
  public dataAddressFrom: AddressData;
  public dataAddressTo: AddressData;
  public dataAddress: AddressData;
  public markpersona: boolean;
  public icon_marca = [
    {
      url: './assets/svg/pin_marca.svg',
      scaledSize: {
          width: 40,
          height: 60
      }
    },
    {
      url: './assets/svg/pin_persona.svg',
      scaledSize: {
          width: 40,
          height: 40
      }
    }
  ];
  // Modals
  @ViewChild("modalAddress") modalAddress;
  @ViewChild("modalSelect") modalSelect;
  @ViewChild("modalHistorialPagos") modalHistorialPagos;
  @ViewChild("modalNotificaciones") modalNotificaciones;
  @ViewChild("modalDatosEnvio") modalDatosEnvio;
  @ViewChild("modalResumenEnvio") modalResumenEnvio;
  // Inputs
  @ViewChild("direccionOrigen") direccionOrigen;
  @ViewChild("direccionDestino") direccionDestino;
  // Barra de Busqueda
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  options = {
    types: [],
    componentRestrictions: { country: 'MX' }
  };
  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase
  ) { 
    this.markers = [];
    this.currentIW = null;
    this.previousIW = null;
    this.markpersona = false;
  }

  enviosConfiguraciones = [
    {
      ciudad: 'Jalisco',
      municipios : [
        {municipio: 'Zapopan'},
        {municipio: 'Tlaquepaque'},
        {municipio: 'Tlajomulco'},
        {municipio: 'Guadalajara'}
      ]
    },
    {
      ciudad: 'Puebla',
      municipios : [
        {municipio: 'Tehuacán'},
        {municipio: 'Coapan'}
      ]
    }
  ];

  //Main
  ngOnInit(): void {
    this.getPosition().then(coords => {
      this.lat = coords.lat;
      this.lng = coords.lng;
      this.zoom = 11;
    });
    this.origin = { lat: 0, lng: 0 };
    this.destination = { lat: 0, lng: 0 };
    this.origenEnvio = "";
    this.destinoEnvio = "";
    this.dataAddressFrom = {
      numero: null, calle: null, colonia: null, ciudad: null,
      estado: null, cp: null, lat: null, lng: null, direccion: null
    };
    this.dataAddressTo = {
      numero: null, calle: null, colonia: null, ciudad: null,
      estado: null, cp: null, lat: null, lng: null, direccion: null
    };
    this.dataAddress = {
      numero: null, calle: null, colonia: null, ciudad: null,
      estado: null, cp: null, lat: null, lng: null, direccion: null
    };
    this.detallesEnvio = {
      nombre_recibe: '',
      telefono_recibe: '',
      que_envia : '',
      vehiculo: [],
      paquete: [],
      total: '',
      distancia: '',
      seguro: false
    };
  }

  // Codigo futuro
  informacionEnvio;
  tipoPaquetes;
  tipoVehiculos;
  selectPaqueteType
  buscarRuta(){
    if(this.dataAddressFrom.estado === this.dataAddressTo.estado){
      this.authService.paquetesGet().then(respPaquetes => {
        this.authService.vehiculosGet().then(respPaquetesVehiculos => {
          this.informacionEnvio = {
            origen: this.dataAddressFrom,
            destino: this.dataAddressTo
          };
          this.selectPaqueteType = false;
          this.tipoPaquetes = respPaquetes;
          this.tipoVehiculos = respPaquetesVehiculos;
          this.modalDatosEnvio.open();
        });
      });
    } else {
      this.toastr.error('El servicio no está disponible entre Estados, por favor modifique la dirección.');
      this.destination = { lat: 0, lng: 0 };
    }
  }

  eventos(event){
    this.detallesEnvio.distancia = (parseFloat(event.routes[0].legs[0].distance.value))/1000;
  }

  // Edicion direccion
  modalEditarDireccion(tipo){
    this.modalAddress.open();
    if(tipo == 'origen'){
      this.dataAddress = this.dataAddressFrom;
    } else {
      this.dataAddress = this.dataAddressTo;
    }
  }

  // Menu
  perfil(){
    this.ngxSmartModalService.create('popModal', PerfilComponent).open();
  }

  markers = [];
  mark_persona;
  oficinas(){
    if(this.markers.length> 0){
      this.markers = [];
    } else {
      this.markers = [{
        lat: 20.6693863,
        lng: -103.3858334,
        label: 'Oficina 1',
        direccion: 'Av Inglaterra 2676-2772, Vallarta, 44690 Guadalajara, Jal.',
        telefono: '3329127771'
      },
      {
        lat: 20.657555,
        lng: -103.370283,
        label: 'Oficina 2',
        direccion: 'Calle Fresno 1976, Del Fresno, 44909 Guadalajara, Jal.',
        telefono: '3329127772'
      }];
    }
    // Agregar pins en mapa
  }

  // Modal origen Direccion
  opcionDireccion(origen){
    this.modalSelect.open();
    this.entradaDatosDireccion = origen;
  }

  // Modal obener direccion
  abrirDireccion(){
    this.modalSelect.close();
    this.modalAddress.open();
  }

  obtenerMiDireccion(){
    if (navigator.geolocation) {
      this.getPosition().then(position=> {
        if (position) {
          this.lat = position.lng;
          this.lng = position.lat;
          this.reverseGeocoding(position).then(address => {
            if (address) {
              let numero = this.getAdress(address.address_components, 'street_number');
              let calle =  this.getAdress(address.address_components, 'route');
              let colonia = this.getAdress(address.address_components, 'sublocality_level_1');
              let ciudad =  this.getAdress(address.address_components, 'locality');
              let estado = this.getAdress(address.address_components, 'administrative_area_level_1');
              let cp =  this.getAdress(address.address_components, 'postal_code');
              let lat =  address.geometry.location.lat();
              let lng =  address.geometry.location.lng();
              let direccion = address.formatted_address;
              this.dataAddress = { 
                numero: numero, calle: calle, colonia: colonia, direccion: direccion,
                ciudad: ciudad, estado: estado, cp: cp, lat: lat, lng: lng
              };
              this.guardarDireccionmodal();
              this.modalSelect.close();
            } else {
              console.log('Not found');
            }
          });
        }
      });
    }
  }

  listaHistorialEventos:any;
  modalHistorial(){
    let keyUser = localStorage.getItem('userKey');
    this.listaHistorialEventos = this.authService.getListEvents(keyUser);
    this.authService.getListEvents(keyUser).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(data => {
      this.listaHistorialEventos  = data;
      this.modalHistorialPagos.open();
    });
  }

  modalNotificacionesHistorial(){
    this.modalNotificaciones.open()
  }

  // Modal Direccion
  public handleAddressChange(address: Address) {
    let numero = this.getAdress(address.address_components, 'street_number');
    let calle =  this.getAdress(address.address_components, 'route');
    let colonia = this.getAdress(address.address_components, 'sublocality_level_1');
    let ciudad =  this.getAdress(address.address_components, 'locality');
    let estado = this.getAdress(address.address_components, 'administrative_area_level_1');
    let cp =  this.getAdress(address.address_components, 'postal_code');
    let lat =  address.geometry.location.lat();
    let lng =  address.geometry.location.lng();
    let direccion = address.formatted_address;
    this.dataAddress = { 
      numero: numero, calle: calle, colonia: colonia, direccion: direccion,
      ciudad: ciudad, estado: estado, cp: cp, lat: lat, lng: lng
    };
  }

  guardarDireccionmodal(){
    let busqueda =  this.buscarCiudad( this.dataAddress.estado);
    if(busqueda.resp){
      if(this.busquedaMunicipio(busqueda.ciudad, this.dataAddress.ciudad)){
        if(this.entradaDatosDireccion == 'origen'){
          this.dataAddressFrom = this.dataAddress;
          this.origin = { lat: this.dataAddress.lat, lng: this.dataAddress.lng };
        } else {
          this.dataAddressTo = this.dataAddress;
          this.destination = { lat: this.dataAddress.lat, lng: this.dataAddress.lng };
        }
        this.dataAddress = {
          numero: '', calle: '', colonia: '', ciudad: '',
          estado: '', cp: '', lat: '', lng: '', direccion: ''
        };
        this.modalAddress.close();
      } else {
        this.toastr.error('El servicio no está disponible en la Ciudad, por favor reintente con otra dirección.')
      }
    } else {
      this.toastr.error('El servicio no está disponible en el Estado, por favor reintente con otra dirección.')
    }
  }

  // Metodos Extra
  getAdress(address, type){
    let dato = '';
    address.forEach(elementAddress => {
      elementAddress.types.forEach(types => {
        if(types === type){
          dato = elementAddress.long_name;
        }
      });
    });
    return dato;
  }

  getPosition(): Promise<any>{
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }

  reverseGeocoding(position): Promise<any>{
    return new Promise((resolve, reject) => {
      let geocoder = new google.maps.Geocoder;
      geocoder.geocode({'location': position}, function(results) {
        if (results[0]) {
          resolve(results[0]);
        }
        else {
          reject([]);
        }
      });
    });
  }

  miUbicacion(){
    this.getPosition().then(coords => {
      this.markpersona = true;
      this.mark_persona = {
        lat: coords.lat,
        lng: coords.lng
      };
      this.lat = coords.lat;
      this.lng = coords.lng;
      this.zoom = 14;
    });
  }
  
  currentIW: AgmInfoWindow;
  previousIW: AgmInfoWindow;

  mapClick(event) {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  markerClick(infoWindow) {
      if (this.previousIW) {
        this.currentIW = infoWindow;
        this.previousIW.close();
      }
      this.previousIW = infoWindow;
  }

  buscarCiudad(ciudadBusqueda){
    const ciudad = this.enviosConfiguraciones.filter(itemCiudad => 
      itemCiudad.ciudad.includes(ciudadBusqueda)
    );
    if(ciudad.length > 0){
      return {resp: true, ciudad: ciudad};
    } else {
      return {resp: false, ciudad:false};
    }
  }

  busquedaMunicipio(ciudad, municipioBusqeuda){
    const municipos = ciudad.filter(group => 
      group.municipios.find( item => item.municipio === municipioBusqeuda)
    );
    if(municipos.length > 0){
      return true;
    } else {
      return false;
    }
  }

  // Modal de envio

  selectVehiculoType;
  envio = {
    nombre_recibe: '',
    telefono_recibe: '',
    que_envia: ''
  };

  selectPaquete(paquete){
    this.selectPaqueteType = true;
    this.tipoPaquetes.forEach(item => {
      item.check = false;
    });
    this.tipoPaquetes.forEach(item => {
      if(item.key === paquete.key){
        item.check = true;
      }
    });
    switch (paquete.tipoPaquete) {
      case 'Sobre':
        this.tipoVehiculos.forEach(item => {
          if(item.key === '-MEsr12gF-ObeOxhh7rw' || item.key === '-MEsrDnQ4pWVJ2qREXNf'){
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      break;
      case 'Caja Chica':
        this.tipoVehiculos.forEach(item => {
          if(item.key === '-MEsr12gF-ObeOxhh7rw' || item.key === '-MEsrDnQ4pWVJ2qREXNf' || item.key === '-MEsrNZvNAcUGFGO6Sij'){
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      break;
      case 'Caja Mediana':
        this.tipoVehiculos.forEach(item => {
          if(item.key === '-MEsrDnQ4pWVJ2qREXNf' || item.key === '-MEsrNZvNAcUGFGO6Sij'){
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      break;
      case 'Caja Grande':
        this.tipoVehiculos.forEach(item => {
          if(item.key === '-MEsrNZvNAcUGFGO6Sij' || item.key === '-MEsrY3PfAD7YeLEQMua'){
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      break;
      case 'Paquete Jumbo':
        this.tipoVehiculos.forEach(item => {
          if(item.key === '-MEsrY3PfAD7YeLEQMua'){
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      break;
    };
    this.tipoVehiculos.forEach(item => {
      item.check = false;
    });
    this.selectVehiculoType = false;
    this.detallesEnvio.paquete = paquete;
  }

  selectVehiculo(vehiculo){
    this.selectVehiculoType = true;
    this.tipoVehiculos.forEach(item => {
      item.check = false;
    });
    this.tipoVehiculos.forEach(item => {
      if(item.key === vehiculo.key){
        item.check = true;
      }
    });
    this.detallesEnvio.vehiculo = vehiculo;
  }

  detallesEnvio:any;
  siguienteEnvio(){
    if(this.envio.nombre_recibe !== '' && this.envio.telefono_recibe !== '' && this.envio.que_envia !== '' && this.selectedFiles !== undefined && this.selectVehiculoType && this.selectPaqueteType){
      this.detallesEnvio.nombre_recibe = this.envio.nombre_recibe;
      this.detallesEnvio.telefono_recibe = this.envio.telefono_recibe;
      this.detallesEnvio.que_envia = this.envio.que_envia;

      let karranque = parseFloat(this.detallesEnvio.vehiculo.kilometrosArranque);
      let distanciaMas =  parseFloat(this.detallesEnvio.distancia) - karranque;
      let total = parseFloat(this.detallesEnvio.vehiculo.costoKilometro) * distanciaMas;
      total = total +  parseFloat(this.detallesEnvio.vehiculo.costoArranque);
      this.detallesEnvio.total = total;
      this.modalResumenEnvio.open();
    } else {
      this.toastr.error('Los campos son necesarios para continuar con el proceso.');
    }
  }

  siguienteResumen(){
    if(this.envio.nombre_recibe !== '' && this.envio.telefono_recibe !== '' && this.envio.que_envia !== '' && this.selectedFiles !== undefined && this.selectVehiculoType && this.selectPaqueteType){
      this.modalResumenEnvio.open();
    } else {
      this.toastr.error('Los campos son necesarios para continuar con el proceso.');
    }
  }

  // Subir foto
  selectedFiles: FileList;
  currentFileUpload: FileUpload;
  percentage: number;
  selectFile(event): void {
    this.selectedFiles = event.target.files;
  }

  uploadImage(): void {
    const file = this.selectedFiles.item(0);
    this.selectedFiles = undefined;
    this.currentFileUpload = new FileUpload(file);
    this.pushFileToStorage(this.currentFileUpload).subscribe(
      percentage => {
        this.percentage = Math.round(percentage);
      },
      error => {
        console.log(error);
      }
    );
  }

  private basePath = '/servicios';
  downloadURL: string;
  pushFileToStorage(fileUpload: FileUpload): Observable<number> {
    let userKey = localStorage.getItem('userKey');
    const filePath = `${this.basePath}/${userKey}/${fileUpload}/paqueteUsuario.jpeg`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);
    uploadTask.snapshotChanges().pipe(
      finalize(async () => {
        // this.downloadURL = await storageRef.getDownloadURL().toPromise();
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  // private saveFileData(fileUpload: FileUpload): void {
  //   this.db.list(this.basePath).push(fileUpload);
  // }

  materiales_prohibidos(){
    console.log('material');
  }

}

export class AddressData {
    direccion: any;
    numero: any;
    calle: any;
    colonia: any;
    ciudad: any;
    estado: any;
    cp: any;
    lat: any;
    lng: any;
}

export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;

  constructor(file: File) {
    this.file = file;
  }
}