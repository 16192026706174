<div>
    <div class="items-historial">
        <div class="row" (click)="agregarTarejta()">
            <div class="col-2">
                <div class="item-card centrado-horizontal" style="margin: 0 auto;">
                    <i class="far fa-credit-card"></i>
                </div>
            </div>
            <div class="col-7">
                <p class="texto-item-historial">Nueva tarjeta</p>
                <p class="sub-texto-item-historial">Acreditación inmediata</p>
            </div>
            <div class="col-3">
                <div class="centrado-horizontal">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" style="margin-top: 20px; margin-bottom: 10px;">
    <div class="col-6" style="text-align: center;">
        <p>Mis tarjetas</p>
    </div>
    <div class="col-6 centrado-horizontal" (click)="getCards(true)">
        <p class="sub-texto-item-historial" style="margin-right: 10px;">Actualizar</p>
        <i class="fas fa-sync"></i>
    </div>
</div>
<div>
    <div class="items-historial" *ngFor="let card of dataCards; let i = index">
        <div class="row" (click)="recargaTarjeta(card)">
            <div class="col-2">
                <div class="centrado-horizontal">
                    <i class="far fa-credit-card"></i>
                </div>
            </div>
            <div class="col-7">
                <p class="texto-item-historial">Tarjeta con terminación {{card.last4}}</p>
                <p class="sub-texto-item-historial">Acreditación Inmediata</p>
            </div>
            <div class="col-3">
                <div class="centrado-horizontal">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-smart-modal #modalNuevaTarjeta identifier="modalNuevaTarjeta" class="nueva-tarjeta" [dismissable]="false" [escapable]="false">
    <h3>Recargar con tarjeta</h3>
    <app-card (reloadCardsEvent)="getCards(true)"></app-card>
</ngx-smart-modal>

<ngx-smart-modal #modalRecargaTarjeta identifier="modalRecargaTarjeta" class="recarga-tarjeta" [dismissable]="false" [escapable]="false">
    <h3>Tarjeta existente</h3>
    <app-recarga (reloadCardsEvent)="getCards(true)" [infoCard]="infoCard"></app-recarga>
</ngx-smart-modal>