import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from 'src/app/shared/services/authservice';

@Component({
  selector: 'app-recarga',
  templateUrl: './recarga.component.html',
  styleUrls: ['./recarga.component.scss']
})
export class RecargaComponent implements OnInit {
  @Output() reloadCardsEvent  = new EventEmitter<boolean>();
  @Input() infoCard:any;
  amount = '';
  constructor(
    private authService:AuthService,
    private ngxSmartModalService: NgxSmartModalService,
  ) { 
    this.amount = '';
  }

  ngOnInit(): void {
  }

  crearCargo(){
    this.authService.chargeCustomer(this.amount, this.infoCard.customer, this.infoCard.id).then( monto => {
      console.log(monto);
      let modal = this.ngxSmartModalService.getModal("modalRecargaTarjeta");
      modal.close();
    });
  }

  eliminarTarjeta(){
    this.authService.deleteCardCustomer(this.infoCard.customer, this.infoCard.id).then( resp => {
      //console.log(resp);
      this.reloadCardsEvent.emit(true);
      let modal = this.ngxSmartModalService.getModal("modalRecargaTarjeta");
      modal.close();
    });
  }

}
