<div class="displayTable">
    <div class="displayTableCell">
        <div class="px-logo logo-login">
            <a href="#" target="_blank">
                <img src="assets/imgs/fondo_login.png" alt="">
            </a>
        </div>
        <div class="authBlock  padding-fonm-login">
            <h3>Gracias por registrarte</h3>
            <div class="formGroup" *ngIf="authService.userData as user">
                <p class="text-center">Hemos enviado un correo electrónico de confirmación a <strong>{{user.email}}</strong></p>
                <p class="text-center">Por favor revise su correo electrónico y haga clic en el enlace para verificar su dirección de correo electrónico.</p>
            </div>
            <div class="formGroup">
                <button type="button" class="btn btnPrimary" (click)="authService.SendVerificationMail()">
            <i class="fas fa-redo-alt"></i>
            Re-enviar correo de verificacion
          </button>
            </div>
            <div class="forgotPassword">
                <span class="padding-t10">Ir a <span class="redirect padding-0" routerLink="/login">Iniciar Sesión</span></span>
            </div>
        </div>
    </div>
</div>